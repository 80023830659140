import * as types from '@/redux/actionTypes'

const initialState = {
  productInventory: {
    productId: null,
    inventory: [],
    lastUpdated: null,
  },
  productList: [],
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PRODUCT_INVENTORY:
      return {
        ...draft,
        productInventory: action.payload,
      }
    case types.UPDATE_PRODUCT_LIST:
      return {
        ...draft,
        productList: action.payload,
      }
    default:
      return draft
  }
}

export const productReducer = ApiRecipe

// Authentication
export const ADD_PKCE_REQUEST = 'ADD_PKCE_REQUEST'
export const ADD_PKCE_SUCCESS = 'ADD_PKCE_SUCCESS'
export const ADD_PKCE_FAILURE = 'ADD_PKCE_FAILURE'

export const AUTH_TOKEN_REQUEST = 'AUTH_TOKEN_REQUEST'
export const AUTH_TOKEN_SUCCESS = 'AUTH_TOKEN_SUCCESS'
export const AUTH_TOKEN_FAILURE = 'AUTH_TOKEN_FAILURE'

export const AUTH_SIGNOUT_REQUEST = 'AUTH_SIGNOUT_REQUEST'
export const AUTH_SIGNOUT_SUCCESS = 'AUTH_SIGNOUT_SUCCESS'
export const AUTH_SIGNOUT_FAILURE = 'AUTH_SIGNOUT_FAILURE'

export const CREATE_ACC_DATA_REQUEST = 'CREATE_ACC_DATA_REQUEST'
export const CREATE_ACC_DATA_SUCCESS = 'CREATE_ACC_DATA_SUCCESS'
export const CREATE_ACC_DATA_FAILURE = 'CREATE_ACC_DATA_FAILURE'

export const LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = 'LOAD_PROFILE_FAILURE'

export const LOAD_ADDRESSES_REQUEST = 'LOAD_ADDRESSES_REQUEST'
export const LOAD_ADDRESSES_SUCCESS = 'LOAD_ADDRESSES_SUCCESS'
export const LOAD_ADDRESSES_FAILURE = 'LOAD_ADDRESSES_FAILURE'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ADDRESS_SUCCESS = 'UPDATE_PROFILE_ADDRESS_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const LOAD_PROFILE_ORDERS_SUCCESS = 'LOAD_PROFILE_ORDERS_SUCCESS'
export const ADD_PROFILE_ADDRESS_SUCCESS = 'ADD_PROFILE_ADDRESS_SUCCESS'
// export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
// export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
// export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const LOAD_WISHLIST_REQUEST = 'LOAD_WISHLIST_REQUEST'
export const LOAD_WISHLIST_SUCCESS = 'LOAD_WISHLIST_SUCCESS'
export const LOAD_WISHLIST_FAILURE = 'LOAD_WISHLIST_FAILURE'

export const ADD_WISHLIST_REQUEST = 'ADD_WISHLIST_REQUEST'
export const ADD_WISHLIST_SUCCESS = 'ADD_WISHLIST_SUCCESS'
export const ADD_WISHLIST_FAILURE = 'ADD_WISHLIST_FAILURE'

export const DELETE_WISHLIST_REQUEST = 'DELETE_WISHLIST_REQUEST'
export const DELETE_WISHLIST_SUCCESS = 'DELETE_WISHLIST_SUCCESS'
export const DELETE_WISHLIST_FAILURE = 'DELETE_WISHLIST_FAILURE'

export const UPDATE_WISHLIST_REQUEST = 'UPDATE_WISHLIST_REQUEST'
export const UPDATE_WISHLIST_SUCCESS = 'UPDATE_WISHLIST_SUCCESS'
export const UPDATE_WISHLIST_FAILURE = 'UPDATE_WISHLIST_FAILURE'

export const REMOVE_WISHLIST_REQUEST = 'REMOVE_WISHLIST_REQUEST'
export const REMOVE_WISHLIST_SUCCESS = 'REMOVE_WISHLIST_SUCCESS'
export const REMOVE_WISHLIST_FAILURE = 'REMOVE_WISHLIST_FAILURE'

export const PROPROGRAM_CODE_VERIFY_REQUEST = 'PROPROGRAM_CODE_VERIFY_REQUEST'
export const PROPROGRAM_CODE_VERIFY_SUCCESS = 'PROPROGRAM_CODE_VERIFY_SUCCESS'
export const PROPROGRAM_CODE_VERIFY_FAILURE = 'PROPROGRAM_CODE_VERIFY_FAILURE'

export const PROPROGRAM_MEMBERSHIP_REQUEST = 'PROPROGRAM_MEMBERSHIP_REQUEST'
export const PROPROGRAM_MEMBERSHIP_SUCCESS = 'PROPROGRAM_MEMBERSHIP_SUCCESS'
export const PROPROGRAM_MEMBERSHIP_FAILURE = 'PROPROGRAM_MEMBERSHIP_FAILURE'

export const PROMO_ORDER_USER_REQUEST = 'PROMO_ORDER_USER_REQUEST'
export const PROMO_ORDER_USER_SUCCESS = 'PROMO_ORDER_USER_SUCCESS'
export const PROMO_ORDER_USER_FAILURE = 'PROMO_ORDER_USER_FAILURE'

// Cart Action
export const UPDATE_ITEM_QTY_REQUEST = 'UPDATE_ITEM_QTY_REQUEST'
export const UPDATE_ITEM_QTY_SUCCESS = 'UPDATE_ITEM_QTY_SUCCESS'
export const UPDATE_ITEM_QTY_FAILURE = 'UPDATE_ITEM_QTY_FAILURE'

export const LOAD_CART_REQUEST = 'LOAD_CART_REQUEST'
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS'
export const LOAD_CART_FAILURE = 'LOAD_CART_FAILURE'

export const ADD_CART_REQUEST = 'ADD_CART_REQUEST'
export const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS'
export const ADD_CART_FAILURE = 'ADD_CART_FAILURE'

export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST'
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS'
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE'

export const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST'
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS'
export const DELETE_CART_FAILURE = 'DELETE_CART_FAILURE'

export const APPLY_CART_PRO_REQUEST = 'APPLY_CART_PRO_REQUEST'
export const APPLY_CART_PRO_SUCCESS = 'APPLY_CART_PRO_SUCCESS'
export const APPLY_CART_PRO_FAILURE = 'APPLY_CART_PRO_FAILURE'

export const REMOVE_CART_PRO_REQUEST = 'REMOVE_CART_PRO_REQUEST'
export const REMOVE_CART_PRO_SUCCESS = 'REMOVE_CART_PRO_SUCCESS'
export const REMOVE_CART_PRO_FAILURE = 'REMOVE_CART_PRO_FAILURE'

export const CLEAR_CART_REQUEST = 'CLEAR_CART_REQUEST'
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS'
export const CLEAR_CART_FAILURE = 'CLEAR_CART_FAILURE'

export const UPDATE_ALL_CART_ITEMS_REQUEST = 'UPDATE_ALL_CART_ITEMS_REQUEST'
export const UPDATE_ALL_CART_ITEMS_SUCCESS = 'UPDATE_ALL_CART_ITEMS_SUCCESS'
export const UPDATE_ALL_CART_ITEMS_FAILURE = 'UPDATE_ALL_CART_ITEMS_FAILURE'

// OrderHistory Action
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'

// UI Action
export const UPDATE_CART_MODAL_REQUEST = 'UPDATE_CART_MODAL_REQUEST'
export const UPDATE_CART_MODAL_SUCCESS = 'UPDATE_CART_MODAL_SUCCESS'
export const UPDATE_CART_MODAL_FAILURE = 'UPDATE_CART_MODAL_FAILURE'

export const UPDATE_LOGIN_MODAL_REQUEST = 'UPDATE_LOGIN_MODAL_REQUEST'
export const UPDATE_LOGIN_MODAL_SUCCESS = 'UPDATE_LOGIN_MODAL_SUCCESS'
export const UPDATE_LOGIN_MODAL_FAILURE = 'UPDATE_LOGIN_MODAL_FAILURE'

export const UPDATE_SIGNUP_MODAL_REQUEST = 'UPDATE_SIGNUP_MODAL_REQUEST'
export const UPDATE_SIGNUP_MODAL_SUCCESS = 'UPDATE_SIGNUP_MODAL_SUCCESS'
export const UPDATE_SIGNUP_MODAL_FAILURE = 'UPDATE_SIGNUP_MODAL_FAILURE'

export const UPDATE_RESET_MODAL_REQUEST = 'UPDATE_RESET_MODAL_REQUEST'
export const UPDATE_RESET_MODAL_SUCCESS = 'UPDATE_RESET_MODAL_SUCCESS'
export const UPDATE_RESET_MODAL_FAILURE = 'UPDATE_RESET_MODAL_FAILURE'

export const REDIRECT_TO_MAIN_PAGE_REQUEST = 'REDIRECT_TO_MAIN_PAGE_REQUEST'
export const REDIRECT_TO_MAIN_PAGE_SUCCESS = 'REDIRECT_TO_MAIN_PAGE_SUCCESS'
export const REDIRECT_TO_MAIN_PAGE_FAILURE = 'REDIRECT_TO_MAIN_PAGE_FAILURE'

//Repair Navigation
export const UPDATE_NAVIGATION_REQUEST = 'UPDATE_NAVIGATION_REQUEST'
export const UPDATE_NAVIGATION_SUCCESS = 'UPDATE_NAVIGATION_SUCCESS'
export const UPDATE_NAVIGATION_FAILURE = 'UPDATE_NAVIGATION_FAILURE'

//Repair Selected Product
export const UPDATE_REPAIR_PRODUCT_REQUEST = 'UPDATE_REPAIR_PRODUCT_REQUEST'
export const UPDATE_REPAIR_PRODUCT_SUCCESS = 'UPDATE_REPAIR_PRODUCT_SUCCESS'
export const UPDATE_REPAIR_PRODUCT_FAILURE = 'UPDATE_REPAIR_PRODUCT_FAILURE'

//Draft Repair
export const UPDATE_DRAFT_REPAIR_REQUEST = 'UPDATE_DRAFT_REPAIR_REQUEST'
export const UPDATE_DRAFT_REPAIR_SUCCESS = 'UPDATE_DRAFT_REPAIR_SUCCESS'
export const UPDATE_DRAFT_REPAIR_FAILURE = 'UPDATE_DRAFT_REPAIR_FAILURE'

//Clear Draft Repair
export const CLEAR_DRAFT_REPAIR_REQUEST = 'CLEAR_DRAFT_REPAIR_REQUEST'
export const CLEAR_DRAFT_REPAIR_SUCCESS = 'CLEAR_DRAFT_REPAIR_SUCCESS'
export const CLEAR_DRAFT_REPAIR_FAILURE = 'CLEAR_DRAFT_REPAIR_FAILURE'

export const LOAD_REPAIRS_REQUEST = 'LOAD_REPAIRS_REQUEST'
export const LOAD_REPAIRS_SUCCESS = 'LOAD_REPAIRS_SUCCESS'
export const LOAD_REPAIRS_FAILURE = 'LOAD_REPAIRS_FAILURE'

//PLP
export const ADD_COMPARE_PRODUCT = 'ADD_COMPARE_PRODUCT'
export const COMPARE_AVAILABLE = 'COMPARE_AVAILABLE'
export const REMOVE_COMPARE_PRODUCT = 'REMOVE_COMPARE_PRODUCT'
export const CLEAR_COMPARE = 'CLEAR_COMPARE'
// export const CLEAR_COMPARED_PRODUCTS = 'CLEAR_COMPARED_PRODUCTS'
// export const UPDATE_ADDING_COMPARE_PRODUCT = 'UPDATE_ADDING_COMPARE_PRODUCT'
// export const CLEAR_DRAFT_REPAIR_FAILURE = 'CLEAR_DRAFT_REPAIR_FAILURE'

// Customer Group
export const LOAD_CUSTOMER_GROUP_REQUEST = 'LOAD_CUSTOMER_GROUP_REQUEST'
export const LOAD_CUSTOMER_GROUP_SUCCESS = 'LOAD_CUSTOMER_GROUP_SUCCESS'
export const LOAD_CUSTOMER_GROUP_FAILURE = 'LOAD_CUSTOMER_GROUP_FAILURE'

// W&R Detail screen
export const WR_YOUR_DETAIL_CHANGE_REQUEST = 'WR_YOUR_DETAIL_CHANGE_REQUEST'
export const WR_YOUR_DETAIL_CHANGE_SUCCESS = 'WR_YOUR_DETAIL_CHANGE_SUCCESS'
export const WR_YOUR_DETAIL_CHANGE_FAILURE = 'WR_YOUR_DETAIL_CHANGE_FAILURE'

// Customer Data Clear
export const CLEAR_CUSTOMER_DATA_REQUEST = 'CLEAR_CUSTOMER_DATA_REQUEST'
export const CLEAR_CUSTOMER_DATA_SUCCESS = 'CLEAR_CUSTOMER_DATA_SUCCESS'
export const CLEAR_CUSTOMER_DATA_FAILURE = 'CLEAR_CUSTOMER_DATA_FAILURE'

// Navigation
export const UPDATE_NAV_ADDITIONAL_CONTENT = 'UPDATE_NAV_ADDITIONAL_CONTENT'

// Size Chart
export const LOAD_SIZE_CHART_REQUEST = 'LOAD_SIZE_CHART_REQUEST'
export const LOAD_SIZE_CHART_SUCCESS = 'LOAD_SIZE_CHART_SUCCESS'
export const LOAD_SIZE_CHART_REQUEST_FAILURE = 'LOAD_SIZE_CHART_REQUEST_FAILURE'

// Product
export const UPDATE_PRODUCT_INVENTORY = 'UPDATE_PRODUCT_INVENTORY'
export const UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST'
